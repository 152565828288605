.ThreeD {
  background: transparent;
  padding-top: 100px;
  font-family: 'Inter var', sans-serif;

  .img {
    /*height: 500px;*/
    right: 100px;
    width: 150px;
    position: fixed;
    align-content: end;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 20px;
    z-index: 2;

  }
  .bg, canvas {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .bg {
    position: absolute;
  }
  h1 {
    z-index: 0;
    position: absolute;
    top: 180px;
    left: 50%;
    font-weight: 900;
    font-size: 10em;
    transform: translate3d(-50%, 0, 0);
    margin: 0;
    color: peru;
    line-height: 0.9em;
    letter-spacing: -10px;
    text-align: left;
  }

  h1 > span {
    white-space: pre;

  }

  @media screen and (max-width: 1000px) {
    height: 1000px;
    h1 {
      transform: translate3d(-50%, 0, 0);
      font-size: 5em;
      letter-spacing: -5px;
      margin-top: -110px;
      padding: 0 5px 0 5px;
    }
    .bg, canvas {
      margin-top: -50px;
      padding: 0;
      width: 100%;
      height: 100%;
    }
    .img {
      margin-top: 150px;
    }
  }

  @media screen and (max-width: 600px) {
    /*margin-top: -150px;*/
    .img {
      right: 50px;
      margin-top: 130px;
      height: 100px;
      width: 100px;
    }
    height: 800px;
    h1 {
      transform: translate3d(-50%, 0%, 0);
      font-size: 3.5em;
      letter-spacing: -2px;
      margin-top: -110px;
      padding: 0 2px 0 2px;
    }
    .bg, canvas {
      margin-top: -50px;
      width: 100% ;
      height: 100%;
    }
  }


}
