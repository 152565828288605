.AppCollection {

    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    //height: 100;

    .titles{
        padding: 120px 0 60px 0;
    }
    .subTitle{
        color: #54afe6;
        letter-spacing: .1rem;
        font-size: 1.2rem;
        margin-bottom: 20px;
        text-align: center;
    }
    .title{
        font-size: 3rem;
        letter-spacing: .5rem;
        margin-bottom: 60px;
        margin: 0 auto;
        color: #fff;
        text-align: center;

    }

    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0 auto;

      }

      .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 50%;
        height: 500px;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
      }

      .swiper-button-next{
        &::after{
            color: #fff;
        }
      }

      .swiper-button-prev{
        &::after{
            color: #ffff;
        }
      }

      ////////////////////////////////////////
      ///

    .team-box {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    }

    .team-name {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .team-name::before {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: .5s;
        margin: auto;
        margin-top: 40px;
        border-radius: 10px;
    }

    .team-name:hover::before {
        width: 60%;
        height: 60%;
    }

    .img {
        width: 70%;
        height: 50%;
        opacity: 0;
        z-index: 999999;
    }

    .team-name:hover {
        backdrop-filter: blur(10px);
    }

    .team-content {
        width: 90%;
        height: auto;
        margin: 400px auto 0;
        padding: 0 10px;
        opacity: 0;
        background-color: #111111;
        //opacity: 0.1;
        .description{
            margin: 10px 0;
        }
        a{
            color: #fff;
            //text-decoration: none;
        }
    }

    .team-content p {
        font-size: .8rem;
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        letter-spacing: .5px;

    }


    .team-content h2 {
        color: #fff;
        font-size: 1rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        letter-spacing: .5px;
        text-transform: uppercase;
        text-decoration: underline;
        padding: 10px;
        //padding-top: 20px;
    }

    //team-box
    .team-box{
        // background-position: center;
        background-size: cover;
        .team-name::before {
            background-size: cover;
            background-repeat: no-repeat;
            //background-position: center;
            //background-position: top;
        }
    }

    .JolieStores {
        background-image: url("../imgs/AppCollection/JolieStore/jolie.webp");
        .team-name::before {
            background-image: url("../imgs/AppCollection/JolieStore/JolieStore1.webp");
        }
    }

    .Twicher {
        background-image: url("../imgs/AppCollection/Twicher/twicher1.webp");
        //background-position: center;
        background-position: top;
        .team-name::before {
            background-image: url("../imgs/AppCollection/Twicher/Twicher 2.webp");
            background-position: center;
        }

    }

    .BibooTV {
        background-image: url("../imgs/AppCollection/Bibootv/bibootv6.webp");
        background-position: top;
        .team-name::before {
            background-image: url("../imgs/AppCollection/Bibootv/bibootv3.webp");
            background-position: top;
        }
    }

    .Dsprintly {
        background-image: url("../imgs/AppCollection/Dsprintly/Dsprintly.webp");
        background-position: top;
        .team-name::before {
            background-image: url("../imgs/AppCollection/Dsprintly/dsprintly2.webp");
            //background-position: center;
        }
    }

    .Deliveroo {
        background-image: url("../imgs/AppCollection/Deliveroo/dating.webp");
        .team-name::before {
            background-image: url("../imgs/AppCollection/Deliveroo/dating1.webp");
            background-position: center;
        }
    }

    .Dsuit {
        background-image: url("../imgs/AppCollection/Dsuit/dsuit4.webp");
        //background-position: center;
        .team-name::before {
            background-image: url("../imgs/AppCollection/Dsuit/Dsuit3.webp");
            background-position: top;
        }
    }


    .team-name:hover .team-content {
        opacity: 1;
    }

}


@media screen and (max-width: 520px) {
    .AppCollectionSlider{
        .titles {
            padding: 0px;
            h2{
                padding: 0;
            }
        }
    }

}