.SlideShow{

}

img{
    width: 100%;
    height: auto;
}
.overlay {
  background: #02050b;
  //background: pink;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //max-width: 1920px;
  //max-height: 1280px;
  opacity: 0.5; // オーバーレイの透明度を調整する場合は適宜変更してください
  //pointer-events: none; // マウスイベントを無効化する
  z-index: 1;
}
.carousel-caption {
    position: absolute;
    top: 40%;
    left: 50%;
    text-align: center;
    z-index: 2;
  }

//custom-button
  .btn-primary{
    //button border
    --bs-btn-border-color: #54afe6 !important;
    //--bs-btn-hover-border-color: #fff !important;
    //--bs-btn-active-border-color: #02050b !important;
    //button background
    --bs-btn-bg:transparent !important;
    --bs-btn-hover-bg:#02050b !important;
    --bs-btn-active-bg: #02050b !important;
    //button color
    --bs-btn-hover-color: blue;
  }

  .carousel-control-next-icon {
    //border: 1px solid #54afe6;
    border: none;
    //border-radius: 50%;
    padding: 30px;
    //box-sizing: border-box !important;
    background-size: 50% 50% !important;
  }

  .carousel-control-prev-icon {
    border: 0;
    //border-radius: 50%;
    padding: 30px;
    //box-sizing: border-box !important;
    background-size: 50% 50% !important;
  }

@media screen and (max-width:500px) {
  .carousel-caption {
    top: 30%;
  }

  .SlideShow{
    //min-height: 45vh;
    min-height: 100vh;
    img{
      //min-height: 45vh;
      min-height: 100vh;
      object-fit: cover;
    }

  }
}