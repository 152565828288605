main {
  position: relative;
  z-index: 5; }

.row > * {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0; }

.row.sm-marg {
  margin-left: -5px;
  margin-right: -5px; }
  .row.sm-marg > * {
    padding-left: 5px;
    padding-right: 5px; }

.row.stand-marg {
  margin-left: -20px;
  margin-right: -20px; }
  .row.stand-marg > * {
    padding-left: 20px;
    padding-right: 20px; }

.row.md-marg {
  margin-left: -25px;
  margin-right: -25px; }
  .row.md-marg > * {
    padding-left: 25px;
    padding-right: 25px; }

.row.lg-marg {
  margin-left: -40px;
  margin-right: -40px; }
  .row.lg-marg > * {
    padding-left: 40px;
    padding-right: 40px; }

.row.xlg-marg {
  margin-left: -50px;
  margin-right: -50px; }
  .row.xlg-marg > * {
    padding-left: 50px;
    padding-right: 50px; }

.row.xxlg-marg {
  margin-left: -80px;
  margin-right: -80px; }
  .row.xxlg-marg > * {
    padding-left: 80px;
    padding-right: 80px; }

.container {
  max-width: 1320px; }

.container-xxl {
  max-width: 1700px; }

.container-xl {
  max-width: 1400px; }

.container-box {
  max-width: 1920px;
  margin: auto; }

img {
  width: 100%;
  height: auto; }

figure {
  margin: 0; }

.btn:hover, .btn:focus, .btn:active {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.alink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* ========= [ animation ]  =========*/
/* --------------- Menu --------------- */
.hamenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #1d1d1d;
  overflow: hidden;
  z-index: 99999999999;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1); }
  .hamenu:after {
    content: '';
    background-image: url(../imgs/icons/menu-shape.png);
    background-size: contain;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: .5; }
  .hamenu .close-menu {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    z-index: 4; }
  .hamenu .close-menu {
    color: #fff;
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s; }
  .hamenu.open .close-menu {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s; }
  .hamenu.open .menu-links .main-menu > li .link {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s; }
  .hamenu.open .cont-info {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
    .hamenu.open .cont-info .text-center {
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
      -webkit-transition-delay: 1.4s;
      -o-transition-delay: 1.4s;
      transition-delay: 1.4s; }
  .hamenu .menu-links {
    color: #fff;
    height: 100vh;
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    overflow: auto;
    width: 100%;
    background: #111;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .hamenu .menu-links::-webkit-scrollbar {
      width: 5px; }
    .hamenu .menu-links::-webkit-scrollbar-track {
      background: #111; }
    .hamenu .menu-links::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 5px; }
    .hamenu .menu-links .main-menu {
      position: relative;
      z-index: 2; }
      .hamenu .menu-links .main-menu ul {
        margin: 0;
        padding: 0; }
      .hamenu .menu-links .main-menu > li {
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s; }
        .hamenu .menu-links .main-menu > li .link {
          font-size: 50px;
          font-weight: 600;
          line-height: 1.2;
          padding: 15px 30px;
          width: 100%;
          position: relative;
          display: block;
          -webkit-transform: translateY(100px);
          -ms-transform: translateY(100px);
          transform: translateY(100px);
          opacity: 0;
          -webkit-transition: all .7s;
          -o-transition: all .7s;
          transition: all .7s; }
          .hamenu .menu-links .main-menu > li .link i {
            position: absolute;
            width: 90px;
            top: 0;
            right: 0;
            bottom: 0; }
            .hamenu .menu-links .main-menu > li .link i:before {
              content: '';
              width: 1px;
              height: 16px;
              position: absolute;
              top: 50%;
              left: 50%;
              background: #fff; }
            .hamenu .menu-links .main-menu > li .link i:after {
              content: '';
              width: 16px;
              height: 1px;
              position: absolute;
              top: calc(50% + 8px);
              left: calc(50% - 8px);
              background: #fff; }
          .hamenu .menu-links .main-menu > li .link.dopen i:before {
            opacity: 0; }
          .hamenu .menu-links .main-menu > li .link .fill-text {
            position: relative;
            color: rgba(255, 255, 255, 0.4); }
            .hamenu .menu-links .main-menu > li .link .fill-text:after {
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              -webkit-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
              -o-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
              transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1); }
        .hamenu .menu-links .main-menu > li.hoverd .link .fill-text:after {
          width: 0; }
        .hamenu .menu-links .main-menu > li .sub-dmenu {
          font-size: 30px;
          font-weight: 500;
          padding-left: 50px;
          text-transform: capitalize;
          letter-spacing: normal;
          background: rgba(255, 255, 255, 0.009); }
      .hamenu .menu-links .main-menu .sub-menu {
        display: none;
        padding: 20px 0; }
        .hamenu .menu-links .main-menu .sub-menu.no-bord {
          border-top: 0;
          padding: 0; }
        .hamenu .menu-links .main-menu .sub-menu li {
          padding: 0;
          margin: 0;
          line-height: 1;
          font-size: 22px;
          font-weight: 400; }
          .hamenu .menu-links .main-menu .sub-menu li a {
            padding: 12px 12px 12px 40px;
            opacity: .6;
            -webkit-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s; }
            .hamenu .menu-links .main-menu .sub-menu li a:hover {
              opacity: 1; }
      .hamenu .menu-links .main-menu .sub-menu2 {
        display: none;
        padding: 10px 0 20px; }
        .hamenu .menu-links .main-menu .sub-menu2 li {
          padding: 0;
          margin: 0;
          line-height: 1;
          font-size: 22px;
          font-weight: 400; }
          .hamenu .menu-links .main-menu .sub-menu2 li a {
            padding: 12px 12px 12px 70px;
            opacity: .6;
            -webkit-transition: all .4s;
            -o-transition: all .4s;
            transition: all .4s; }
            .hamenu .menu-links .main-menu .sub-menu2 li a:hover {
              opacity: 1; }
  .hamenu .cont-info {
    padding-left: 30px;
    width: 70%;
    color: #fff;
    background: #1d1d1d; }
    .hamenu .cont-info .logo {
      width: 200px;
      margin: auto; }
    .hamenu .cont-info .social-icon a {
      margin: 0 15px; }
    .hamenu .cont-info .text-center {
      -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
      transform: translateY(50px);
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s;
      opacity: 0; }
      .hamenu .cont-info .text-center h5 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: normal;
        line-height: 1.7; }

@media screen and (max-width: 768px) {
  .hamenu.open .cont-info {
    display: none; }
  .hamenu .menu-links {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px; }
  .hamenu .menu-links .main-menu {
    width: 100%; }
  .hamenu .menu-links .main-menu > li .link {
    font-size: 30px; } }

/* ========= [ preloader ]  =========*/
/* Preloader
-----------------------------------------------------------------*/
body.loaded {
  overflow: hidden !important;
  height: 100% !important; }

.loader-wrap {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
  z-index: 99999999999999; }

.loader-wrap svg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
  fill: #fff; }

.loader-wrap .loader-wrap-heading .load-text {
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 15px;
  text-transform: uppercase;
  z-index: 20; }

.load-text span {
  -webkit-animation: loading 1s infinite alternate;
  animation: loading 1s infinite alternate; }

.load-text span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.load-text span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.load-text span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.load-text span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.load-text span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.load-text span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.load-text span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

@-webkit-keyframes loading {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes loading {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* ========= [ typography ]  =========*/
/* Typography
-----------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none; }

body {
  overflow-x: hidden !important; }

a, a:hover {
  color: inherit; }

span {
  display: inline-block; }

a {
  display: inline-block;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

/* ========= [ buttons ]  =========*/
/* Buttons
-----------------------------------------------------------------*/
.butn {
  font-size: 14px;
  font-weight: 500; }
  .butn.butn-md {
    padding: 12px 25px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s; }
  .butn.butn-rounded {
    border-radius: 30px; }
  .butn.butn-bord {
    border: 1px solid #ccc; }
    .butn.butn-bord:hover {
      background: #212121;
      color: #fff; }

.butn-circle {
  width: 180px;
  height: 180px;
  border: 1px solid #141414;
  border-radius: 50%;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s; }
  .butn-circle.butn-dark {
    color: #fff;
    background: #141414; }
  .butn-circle.butn-light {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.2); }
    .butn-circle.butn-light:hover {
      background: #fff;
      color: #141414; }
      .butn-circle.butn-light:hover .text {
        color: #141414; }
      .butn-circle.butn-light:hover svg path {
        stroke: #141414; }
  .butn-circle svg path {
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s; }
  .butn-circle:hover {
    background: #141414;
    color: #fff; }
    .butn-circle:hover svg path {
      stroke: #fff; }
    .butn-circle:hover .text-dark {
      color: #fff !important; }

/* ========= [ cursor ]  =========*/
/* Cursor
-----------------------------------------------------------------*/
.hover-this .hover-anim {
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear; }

.cursor {
  pointer-events: none;
  position: fixed;
  padding: 0.3rem;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.4s ease, -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, opacity 0.4s ease, -webkit-transform 0.3s ease;
  z-index: 99999; }

.cursor-active {
  -webkit-transform: translate(-50%, -50%) scale(8);
  -ms-transform: translate(-50%, -50%) scale(8);
  transform: translate(-50%, -50%) scale(8);
  opacity: .1; }

.rolling-text {
  display: inline-block;
  overflow: hidden;
  line-height: 50px;
  height: 50px; }
  .rolling-text.butn {
    padding: 0 35px; }

.social-text .rolling-text {
  line-height: 30px;
  height: 30px; }

.rolling-text:hover .letter,
.rolling-text.play .letter {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%); }

.rolling-text .letter {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  -o-transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1), -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1); }

.letter:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s; }

.letter:nth-child(2) {
  -webkit-transition-delay: 0.015s;
  -o-transition-delay: 0.015s;
  transition-delay: 0.015s; }

.letter:nth-child(3) {
  -webkit-transition-delay: 0.03s;
  -o-transition-delay: 0.03s;
  transition-delay: 0.03s; }

.letter:nth-child(4) {
  -webkit-transition-delay: 0.045s;
  -o-transition-delay: 0.045s;
  transition-delay: 0.045s; }

.letter:nth-child(5) {
  -webkit-transition-delay: 0.06s;
  -o-transition-delay: 0.06s;
  transition-delay: 0.06s; }

.letter:nth-child(6) {
  -webkit-transition-delay: 0.075s;
  -o-transition-delay: 0.075s;
  transition-delay: 0.075s; }

.letter:nth-child(7) {
  -webkit-transition-delay: 0.09s;
  -o-transition-delay: 0.09s;
  transition-delay: 0.09s; }

.letter:nth-child(8) {
  -webkit-transition-delay: 0.105s;
  -o-transition-delay: 0.105s;
  transition-delay: 0.105s; }

.letter:nth-child(9) {
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s; }

.letter:nth-child(10) {
  -webkit-transition-delay: 0.135s;
  -o-transition-delay: 0.135s;
  transition-delay: 0.135s; }

.letter:nth-child(11) {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s; }

.letter:nth-child(12) {
  -webkit-transition-delay: 0.165s;
  -o-transition-delay: 0.165s;
  transition-delay: 0.165s; }

.letter:nth-child(13) {
  -webkit-transition-delay: 0.18s;
  -o-transition-delay: 0.18s;
  transition-delay: 0.18s; }

.letter:nth-child(14) {
  -webkit-transition-delay: 0.195s;
  -o-transition-delay: 0.195s;
  transition-delay: 0.195s; }

.letter:nth-child(15) {
  -webkit-transition-delay: 0.21s;
  -o-transition-delay: 0.21s;
  transition-delay: 0.21s; }

.letter:nth-child(16) {
  -webkit-transition-delay: 0.225s;
  -o-transition-delay: 0.225s;
  transition-delay: 0.225s; }

.letter:nth-child(17) {
  -webkit-transition-delay: 0.24s;
  -o-transition-delay: 0.24s;
  transition-delay: 0.24s; }

.letter:nth-child(18) {
  -webkit-transition-delay: 0.255s;
  -o-transition-delay: 0.255s;
  transition-delay: 0.255s; }

.letter:nth-child(19) {
  -webkit-transition-delay: 0.27s;
  -o-transition-delay: 0.27s;
  transition-delay: 0.27s; }

.letter:nth-child(20) {
  -webkit-transition-delay: 0.285s;
  -o-transition-delay: 0.285s;
  transition-delay: 0.285s; }

.letter:nth-child(21) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  mix-blend-mode: difference; }

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.progress-wrap::after {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  content: '\f077';
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  color: #ccc;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear; }

.progress-wrap svg path {
  fill: none; }

.progress-wrap svg.progress-circle path {
  stroke: #ccc;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear; }

/* ========= [ extra ]  =========*/
/* Extra
-----------------------------------------------------------------*/
.d-rotate {
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  .d-rotate .rotate-text {
    display: block;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s;
    -webkit-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: translate3d(0px, 50px, -50px) rotateX(-45deg) scale(0.95);
    transform: translate3d(0px, 50px, -50px) rotateX(-45deg) scale(0.95);
    opacity: 0;
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s; }
  .d-rotate.animated .rotate-text {
    -webkit-transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
    transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
    opacity: 1; }

.d-slideup .sideup-text {
  overflow: hidden;
  display: block; }
  .d-slideup .sideup-text:first-of-type .up-text {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s; }
  .d-slideup .sideup-text:nth-of-type(2) .up-text {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s; }
  .d-slideup .sideup-text:nth-of-type(3) .up-text {
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s; }
  .d-slideup .sideup-text:nth-of-type(4) .up-text {
    -webkit-transition-delay: 1.4s;
    -o-transition-delay: 1.4s;
    transition-delay: 1.4s; }
  .d-slideup .sideup-text:nth-of-type(5) .up-text {
    -webkit-transition-delay: 1.8s;
    -o-transition-delay: 1.8s;
    transition-delay: 1.8s; }
  .d-slideup .sideup-text .up-text {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all .8s;
    -o-transition: all .8s;
    transition: all .8s; }

.d-slideup.animated .up-text {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0); }

.main-marq {
  position: relative;
  padding: 0;
  overflow: hidden !important; }
  .main-marq:after {
    content: '';
    position: absolute;
    top: -30px;
    bottom: -30px;
    left: -30px;
    right: -30px;
    pointer-events: none;
    background: -webkit-linear-gradient(right, #fff, transparent 280px, transparent calc(100% - 280px), #fff);
    background: -o-linear-gradient(right, #fff, transparent 280px, transparent calc(100% - 280px), #fff);
    background: linear-gradient(to left, #fff, transparent 280px, transparent calc(100% - 280px), #fff); }
  .main-marq.shadow-off:after {
    display: none; }
  .main-marq.md-text .box .item h4 {
    font-size: 40px; }
  .main-marq.lrg .box .item h4 {
    font-size: 5vw; }
  .main-marq.xlrg .box .item {
    padding: 0 30px; }
    .main-marq.xlrg .box .item h4 {
      font-size: 8vw; }
  .main-marq .slide-har {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    .main-marq .slide-har .overlay-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .main-marq .strok .item h4 {
    color: transparent !important;
    -webkit-text-stroke: .5px #fff; }
    .main-marq .strok .item h4 a {
      color: transparent !important;
      -webkit-text-stroke: .5px #fff; }
  .main-marq .non-strok .item h4 {
    color: #fff !important;
    -webkit-text-stroke: 0 !important; }
    .main-marq .non-strok .item h4 a {
      color: #fff !important;
      -webkit-text-stroke: 0 !important; }
  .main-marq .box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .main-marq .box .item {
      padding: 0 30px; }
      .main-marq .box .item h4 {
        white-space: nowrap;
        margin: 0; }
        .main-marq .box .item h4 .icon {
          margin-bottom: -15px; }
      .main-marq .box .item:nth-of-type(even) h4 {
        color: transparent;
        -webkit-text-stroke: .5px #fff; }
        .main-marq .box .item:nth-of-type(even) h4 a {
          color: transparent;
          -webkit-text-stroke: .5px #fff; }
    .main-marq .box:last-of-type .item:nth-of-type(even) h4 {
      color: #fff;
      -webkit-text-stroke: 0; }
      .main-marq .box:last-of-type .item:nth-of-type(even) h4 a {
        color: #fff;
        -webkit-text-stroke: 0; }
    .main-marq .box:last-of-type .item:nth-of-type(odd) h4 {
      color: transparent;
      -webkit-text-stroke: 1px #fff; }
      .main-marq .box:last-of-type .item:nth-of-type(odd) h4 a {
        color: transparent;
        -webkit-text-stroke: 1px #fff; }

.slide-har {
  position: relative; }
  .slide-har.st1 .box {
    position: relative;
    -webkit-animation: slide-har 80s linear infinite;
    animation: slide-har 80s linear infinite; }
  .slide-har.st2 .box {
    position: relative;
    -webkit-animation: slide-har-revers 80s linear infinite;
    animation: slide-har-revers 80s linear infinite; }

@-webkit-keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@-webkit-keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

@keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%); } }

.main-marqv {
  position: relative;
  padding: 0; }
  .main-marqv:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: -webkit-linear-gradient(bottom, #fff, transparent 80px, transparent calc(100% - 80px), #fff);
    background: -o-linear-gradient(bottom, #fff, transparent 80px, transparent calc(100% - 80px), #fff);
    background: linear-gradient(to top, #fff, transparent 80px, transparent calc(100% - 80px), #fff);
    pointer-events: none; }
  .main-marqv .slide-vertical {
    overflow: hidden;
    height: 600px;
    margin: 0 auto;
    position: relative; }
    .main-marqv .slide-vertical:hover .box {
      -webkit-animation-play-state: paused !important;
      animation-play-state: paused !important; }
    .main-marqv .slide-vertical .box {
      position: relative;
      -webkit-animation: slide-vertical 50s linear infinite;
      animation: slide-vertical 50s linear infinite; }

@-webkit-keyframes slide-vertical {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); } }

@keyframes slide-vertical {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); } }

.imago {
  -webkit-clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transition: -webkit-clip-path .8s ease-in-out .3s, -webkit-transform .8s ease-in-out 1.1s, -webkit-filter .5s ease-in-out 1.1s;
  transition: -webkit-clip-path .8s ease-in-out .3s, -webkit-transform .8s ease-in-out 1.1s, -webkit-filter .5s ease-in-out 1.1s;
  -o-transition: clip-path .8s ease-in-out .3s, transform .8s ease-in-out 1.1s, filter .5s ease-in-out 1.1s;
  transition: clip-path .8s ease-in-out .3s, transform .8s ease-in-out 1.1s, filter .5s ease-in-out 1.1s;
  transition: clip-path .8s ease-in-out .3s, transform .8s ease-in-out 1.1s, filter .5s ease-in-out 1.1s, -webkit-clip-path .8s ease-in-out .3s, -webkit-transform .8s ease-in-out 1.1s, -webkit-filter .5s ease-in-out 1.1s;
  position: relative; }
  .imago.animated {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px); }
  .imago.simpl {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px); }

/* ========= [ overlay ]  =========*/
/* ====================== [ Start Overlay ] ====================== */
[data-overlay-dark],
[data-overlay-light] {
  position: relative; }

[data-overlay-dark] .container,
[data-overlay-dark] .container-xxl,
[data-overlay-light] .container-xxl,
[data-overlay-light] .container {
  position: relative;
  z-index: 7; }

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none; }

[data-overlay-dark]:before {
  background: #141414; }

[data-overlay-light]:before {
  background: #fff; }

[data-overlay-dark],
[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
  color: #fff; }

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0; }

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: .1; }

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: .2; }

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: .3; }

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: .4; }

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: .5; }

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: .6; }

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: .7; }

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: .8; }

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
  opacity: .9; }

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1; }

/* ====================== [ End Overlay ] ====================== */
/* ========= [ helper ]  =========*/
/* ====================== [ Start Helper Classes  ] ====================== */
.o-hidden {
  overflow: hidden; }

.position-re {
  position: relative; }

.position-abs {
  position: absolute; }

.cursor-pointer {
  cursor: pointer; }

.revers-x {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg); }

.revers-y {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg); }

.full-over {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.ontop {
  position: relative;
  z-index: 7; }

.rest {
  padding: 0 !important;
  margin: 0 !important; }

img.out-box {
  width: 140%; }

img.left {
  margin-left: -40%; }

.no-bord {
  border: 0 !important; }

.bord-thin-top {
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.bord-thin-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.bord-thin-right {
  border-right: 1px solid rgba(0, 0, 0, 0.15); }

.bord-thin-left {
  border-left: 1px solid rgba(0, 0, 0, 0.15); }

.bord-thin-top-light {
  border-top: 1px solid rgba(255, 255, 255, 0.15); }

.bord-thin-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }

.bord-thin-right-light {
  border-right: 1px solid rgba(255, 255, 255, 0.15); }

.bord-thin-left-light {
  border-left: 1px solid rgba(255, 255, 255, 0.15); }

.radius-5 {
  border-radius: 5px !important; }

.radius-10 {
  border-radius: 10px !important; }

.radius-15 {
  border-radius: 15px !important; }

.radius-20 {
  border-radius: 20px !important; }

.radius-25 {
  border-radius: 25px !important; }

.radius-30 {
  border-radius: 30px !important; }

.radius-50 {
  border-radius: 50px; }

.order-md-1,
.order-md-2,
.order-md-3,
.order-md-4 {
  -webkit-box-ordinal-group: inherit !important;
  -ms-flex-order: inherit !important;
  order: inherit !important; }

.full-width {
  width: 100% !important; }

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.bg-top {
  background-position: center top; }

.bg-bottom {
  background-position: center bottom; }

.bg-center {
  background-position: center center; }

.bg-fixed {
  background-attachment: fixed; }

.bg-repeat {
  background-size: auto;
  background-repeat: repeat; }

.bg-auto {
  background-size: auto;
  background-repeat: no-repeat; }

.section-padding {
  padding-top: 140px;
  padding-bottom: 140px; }

.section-ptb {
  padding: 80px 0; }

.cmd-padding {
  padding: 0 10px; }

.csm-padding {
  padding: 0 5px; }

.head-pt {
  padding: 80px 0 0; }

.head-pb {
  padding: 0 0 80px; }

.no-shad {
  -webkit-box-shadow: none;
  box-shadow: none; }

.stroke {
  color: transparent;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.7); }

.fit-img {
  overflow: hidden; }
  .fit-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center; }

.rounded {
  border-radius: 5px; }

.circle {
  border-radius: 50%; }

.circle-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center; }

.circle-30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  overflow: hidden; }

.circle-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  overflow: hidden; }

.circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  overflow: hidden; }

.circle-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  overflow: hidden; }

.circle-70 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  overflow: hidden; }

.circle-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  overflow: hidden; }

.circle-90 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  overflow: hidden; }

.circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  overflow: hidden; }

.circle-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  overflow: hidden; }

.nowrap {
  white-space: nowrap; }

.balance {
  text-wrap: balance; }

.unlist {
  list-style: none; }

.underline a {
  text-decoration: underline !important; }

.underline span {
  text-decoration: underline; }

.undecro:hover {
  text-decoration: none; }

.inline-b {
  display: inline-block; }

.inline {
  display: inline; }

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.horizontal-link li {
  display: inline-block; }

.rest {
  margin: 0;
  padding: 0; }

.plink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.social.circle-bord a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 50%; }

.primery-shadow {
  -webkit-box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1); }

/*-------------------------------------------------------
           icon Size
-------------------------------------------------------*/
.icon-img-20 {
  width: 20px; }

.icon-img-30 {
  width: 30px; }

.icon-img-40 {
  width: 40px; }

.icon-img-50 {
  width: 50px; }

.icon-img-60 {
  width: 60px; }

.icon-img-70 {
  width: 70px; }

.icon-img-80 {
  width: 80px; }

.icon-img-90 {
  width: 90px; }

.icon-img-100 {
  width: 100px; }

.icon-img-120 {
  width: 120px; }

/*-------------------------------------------------------
           Z-index
-------------------------------------------------------*/
.zindex-1 {
  z-index: 1; }

.zindex-2 {
  z-index: 2; }

.zindex-3 {
  z-index: 3; }

.zindex-4 {
  z-index: 4; }

.zindex-5 {
  z-index: 5; }

.zindex-10 {
  z-index: 10; }

.zindex-99 {
  z-index: 99; }

/*-------------------------------------------------------
           opacity
-------------------------------------------------------*/
.opacity-05 {
  opacity: .05 !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-1 {
  opacity: .1 !important; }

.opacity-2 {
  opacity: .2 !important; }

.opacity-3 {
  opacity: .3 !important; }

.opacity-4 {
  opacity: .4 !important; }

.opacity-5 {
  opacity: .5 !important; }

.opacity-6 {
  opacity: .6 !important; }

.opacity-7 {
  opacity: .7 !important; }

.opacity-8 {
  opacity: .8 !important; }

.opacity-9 {
  opacity: .9 !important; }

/*-------------------------------------------------------
           text color
-------------------------------------------------------*/
.text-white {
  color: #FFF; }

.text-black {
  color: #000; }

.text-dark {
  color: #444; }

.text-extra-light-gray {
  color: #b7b7b7; }

.text-hover-inherit:hover {
  color: inherit !important; }

.text-hover-light:hover {
  color: #fff !important; }

/*-------------------------------------------------------
          font-weight
-------------------------------------------------------*/
.fw-100 {
  font-weight: 100 !important; }

.fw-200 {
  font-weight: 200 !important; }

.fw-300 {
  font-weight: 300 !important; }

.fw-400 {
  font-weight: 400 !important; }

.fw-500 {
  font-weight: 500 !important; }

.fw-600 {
  font-weight: 600 !important; }

.fw-700 {
  font-weight: 700 !important; }

.fw-800 {
  font-weight: 800 !important; }

.fw-900 {
  font-weight: 900 !important; }

/*-------------------------------------------------------
           font-size
-------------------------------------------------------*/
.fz-10 {
  font-size: 10px !important; }

.fz-12 {
  font-size: 12px !important; }

.fz-13 {
  font-size: 13px !important; }

.fz-14 {
  font-size: 14px !important; }

.fz-15 {
  font-size: 15px !important; }

.fz-16 {
  font-size: 16px !important; }

.fz-18 {
  font-size: 18px !important; }

.fz-20 {
  font-size: 20px !important; }

.fz-22 {
  font-size: 22px !important; }

.fz-24 {
  font-size: 24px !important; }

.fz-25 {
  font-size: 25px !important; }

.fz-26 {
  font-size: 26px !important; }

.fz-28 {
  font-size: 28px !important; }

.fz-30 {
  font-size: 30px !important; }

.fz-35 {
  font-size: 35px !important; }

.fz-40 {
  font-size: 40px !important; }

.fz-42 {
  font-size: 42px !important; }

.fz-44 {
  font-size: 44px !important; }

.fz-45 {
  font-size: 45px !important; }

.fz-46 {
  font-size: 46px !important; }

.fz-48 {
  font-size: 48px !important; }

.fz-50 {
  font-size: 50px !important; }

.fz-55 {
  font-size: 55px !important; }

.fz-60 {
  font-size: 60px !important; }

.fz-65 {
  font-size: 65px !important; }

.fz-70 {
  font-size: 70px !important; }

.fz-80 {
  font-size: 80px !important; }

.fz-100 {
  font-size: 100px !important;
  line-height: 1.1; }

.fz-120 {
  font-size: 120px !important;
  line-height: 1.1; }

/*-------------------------------------------------------
          line-height
-------------------------------------------------------*/
.line-height-1 {
  line-height: 1; }

.line-height-10 {
  line-height: 10px; }

.line-height-13 {
  line-height: 13px; }

.line-height-18 {
  line-height: 18px; }

.line-height-20 {
  line-height: 20px; }

.line-height-22 {
  line-height: 22px; }

.line-height-24 {
  line-height: 24px; }

.line-height-26 {
  line-height: 26px; }

.line-height-28 {
  line-height: 28px; }

.line-height-30 {
  line-height: 30px; }

.line-height-35 {
  line-height: 35px; }

.line-height-40 {
  line-height: 40px; }

.line-height-45 {
  line-height: 45px; }

.line-height-50 {
  line-height: 50px; }

.line-height-55 {
  line-height: 55px; }

.line-height-60 {
  line-height: 60px; }

.line-height-65 {
  line-height: 65px; }

.line-height-70 {
  line-height: 70px; }

.line-height-75 {
  line-height: 75px; }

.line-height-80 {
  line-height: 80px; }

.line-height-85 {
  line-height: 85px; }

.line-height-90 {
  line-height: 90px; }

.line-height-95 {
  line-height: 95px; }

.line-height-100 {
  line-height: 100px; }

.line-height-110 {
  line-height: 110px; }

.line-height-120 {
  line-height: 120px; }

/*-------------------------------------------------------
          text-transform
-------------------------------------------------------*/
.text-u {
  text-transform: uppercase !important; }

.text-l {
  text-transform: lowercase !important; }

.text-c {
  text-transform: capitalize !important; }

.text-non {
  text-transform: none !important; }

.text-i {
  font-style: italic; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/*-------------------------------------------------------
           letter-spacing
-------------------------------------------------------*/
.ls0 {
  letter-spacing: 0px !important; }

.ls1 {
  letter-spacing: 1px !important; }

.ls2 {
  letter-spacing: 2px !important; }

.ls3 {
  letter-spacing: 3px !important; }

.ls4 {
  letter-spacing: 4px !important; }

.ls5 {
  letter-spacing: 5px !important; }

.ls6 {
  letter-spacing: 6px !important; }

.ls7 {
  letter-spacing: 7px !important; }

.ls8 {
  letter-spacing: 8px !important; }

.ls9 {
  letter-spacing: 9px !important; }

.ls10 {
  letter-spacing: 10px !important; }

.ls15 {
  letter-spacing: 15px !important; }

.ls20 {
  letter-spacing: 20px !important; }

.ls25 {
  letter-spacing: 25px !important; }

.ls30 {
  letter-spacing: 30px !important; }

/*-------------------------------------------------------
       margin-top  (0/100) +5
-------------------------------------------------------*/
.mt-0 {
  margin-top: 0 !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-62 {
  margin-top: 62px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-120 {
  margin-top: 120px !important; }

.mt-140 {
  margin-top: 140px !important; }

/*-------------------------------------------------------
       margin-bottom  (0/100) +5
-------------------------------------------------------*/
.mb-0 {
  margin-bottom: 0 !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-62 {
  margin-bottom: 62px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-120 {
  margin-bottom: 120px !important; }

.mb-140 {
  margin-bottom: 140px !important; }

/*-------------------------------------------------------
       margin-right  (0/100) +5
-------------------------------------------------------*/
.mr-0 {
  margin-right: 0 !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mr-62 {
  margin-right: 62px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mr-100 {
  margin-right: 100px !important; }

/*-------------------------------------------------------
       margin-left  (0/100) +5
-------------------------------------------------------*/
.ml-auto {
  margin-left: auto; }

.ml-0 {
  margin-left: 0 !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-45 {
  margin-left: 45px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-55 {
  margin-left: 55px !important; }

.ml-60 {
  margin-left: 60px !important; }

.ml-62 {
  margin-left: 62px !important; }

.ml-65 {
  margin-left: 65px !important; }

.ml-70 {
  margin-left: 70px !important; }

.ml-75 {
  margin-left: 75px !important; }

.ml-80 {
  margin-left: 80px !important; }

.ml-85 {
  margin-left: 85px !important; }

.ml-90 {
  margin-left: 90px !important; }

.ml-95 {
  margin-left: 95px !important; }

.ml-100 {
  margin-left: 100px !important; }

.m-auto {
  margin-left: auto;
  margin-right: auto; }

.min-30 {
  margin-left: -30px;
  margin-right: -30px; }

/*-------------------------------------------------------
      margin left right  (0/50) +5
-------------------------------------------------------*/
.margin-rl-5 {
  margin-right: 5px;
  margin-left: 5px; }

.margin-rl-10 {
  margin-right: 10px;
  margin-left: 10px; }

.margin-rl-15 {
  margin-right: 15px;
  margin-left: 15px; }

.margin-rl-20 {
  margin-right: 20px;
  margin-left: 20px; }

.margin-rl-25 {
  margin-right: 25px;
  margin-left: 25px; }

.margin-rl-30 {
  margin-right: 30px;
  margin-left: 30px; }

.margin-rl-35 {
  margin-right: 35px;
  margin-left: 35px; }

.margin-rl-40 {
  margin-right: 40px;
  margin-left: 40px; }

.margin-rl-45 {
  margin-right: 45px;
  margin-left: 45px; }

.margin-rl-50 {
  margin-right: 50px;
  margin-left: 50px; }

/*-------------------------------------------------------
      padding-top  (0/100) +5
-------------------------------------------------------*/
.pt-0 {
  padding-top: 0 !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-120 {
  padding-top: 120px !important; }

.pt-140 {
  padding-top: 140px !important; }

/*-------------------------------------------------------
     padding-bottom  (0/100) +5
-------------------------------------------------------*/
.pb-0 {
  padding-bottom: 0 !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-120 {
  padding-bottom: 120px !important; }

.pb-140 {
  padding-bottom: 140px !important; }

.no-padding {
  padding: 0 !important; }

.pr-30 {
  padding-right: 30px; }

.pr-50 {
  padding-right: 50px; }

.pl-30 {
  padding-left: 30px; }

.pl-50 {
  padding-left: 50px; }

/*-------------------------------------------------------
      padding left right  (0/50) +5
-------------------------------------------------------*/
.padding-rl-5 {
  padding-right: 5px;
  padding-left: 5px; }

.padding-rl-10 {
  padding-right: 10px;
  padding-left: 10px; }

.padding-rl-15 {
  padding-right: 15px;
  padding-left: 15px; }

.padding-rl-20 {
  padding-right: 20px;
  padding-left: 20px; }

.padding-rl-25 {
  padding-right: 25px;
  padding-left: 25px; }

.padding-rl-30 {
  padding-right: 30px;
  padding-left: 30px; }

.padding-rl-35 {
  padding-right: 35px;
  padding-left: 35px; }

.padding-rl-40 {
  padding-right: 40px;
  padding-left: 40px; }

.padding-rl-45 {
  padding-right: 45px;
  padding-left: 45px; }

.padding-rl-50 {
  padding-right: 50px;
  padding-left: 50px; }

/* ====================== [ End Helper Classes  ] ====================== */
/* ========= [ responsive ]  =========*/
/* ====================== [ Start Responsive ] ====================== */
@media screen and (max-width: 992px) {
  .md-mb10 {
    margin-bottom: 10px; }
  .md-mb15 {
    margin-bottom: 15px; }
  .md-mb20 {
    margin-bottom: 20px; }
  .md-mb30 {
    margin-bottom: 30px; }
  .md-mb40 {
    margin-bottom: 40px; }
  .md-mb50 {
    margin-bottom: 50px !important; }
  .md-mb80 {
    margin-bottom: 80px; }
  .md-no-mrg {
    margin: 0 !important; }
  .md-pb10 {
    padding-bottom: 10px; }
  .md-pb20 {
    padding-bottom: 20px; }
  .md-pb30 {
    padding-bottom: 30px; }
  .md-pb40 {
    padding-bottom: 40px; }
  .md-pb50 {
    padding-bottom: 50px; }
  .md-pb60 {
    padding-bottom: 60px; }
  .md-pb70 {
    padding-bottom: 70px; }
  .md-pb80 {
    padding-bottom: 80px; }
  .md-pt80 {
    padding-top: 80px !important; }
  .md-no-pad {
    padding: 0 !important; }
  .md-center {
    text-align: center !important; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important; }
  .md-hide {
    display: none; }
  .justify-end {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
    .justify-end .ml-auto {
      margin-left: 0;
      margin-top: 30px; }
  .row.sm-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.sm-marg > * {
      padding-left: 15px;
      padding-right: 15px; }
  .row.stand-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.stand-marg > * {
      padding-left: 15px;
      padding-right: 15px; }
  .row.md-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.md-marg > * {
      padding-left: 15px;
      padding-right: 15px; }
  .row.lg-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.lg-marg > * {
      padding-left: 15px;
      padding-right: 15px; }
  .row.xlg-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.xlg-marg > * {
      padding-left: 15px;
      padding-right: 15px; }
  .row.xxlg-marg {
    margin-left: -15px;
    margin-right: -15px; }
    .row.xxlg-marg > * {
      padding-left: 15px;
      padding-right: 15px; } }

@media screen and (max-width: 768px) {
  .sm-mb10 {
    margin-bottom: 10px; }
  .sm-mb20 {
    margin-bottom: 20px; }
  .sm-mb30 {
    margin-bottom: 30px; }
  .sm-mb40 {
    margin-bottom: 40px; }
  .sm-mb50 {
    margin-bottom: 50px; }
  .sm-mb60 {
    margin-bottom: 60px; }
  .sm-mb80 {
    margin-bottom: 80px; }
  .sm-pb10 {
    padding-bottom: 10px; }
  .sm-pb20 {
    padding-bottom: 20px; }
  .sm-pb30 {
    padding-bottom: 30px; }
  .sm-pb40 {
    padding-bottom: 40px; }
  .sm-pb50 {
    padding-bottom: 50px; }
  .sm-pb60 {
    padding-bottom: 60px; }
  .sm-pb70 {
    padding-bottom: 70px; }
  .sm-pb80 {
    padding-bottom: 80px; }
  .sm-hide {
    display: none !important; }
  .justify-end {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
    .justify-end .ml-auto {
      margin-left: 0;
      margin-top: 30px; }
  .progress-wrap {
    display: none; } }

@media screen and (max-width: 480px) {
  .justify-end {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; } }

/* ====================== [ End Responsive ] ====================== */
