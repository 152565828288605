footer{
    position: relative;
    padding: 50px 0;
    overflow: hidden;
    ul{
        margin-left: 0;

        li{
            list-style: none;
            margin-bottom: 30px;

            a{
                text-decoration: none;
                color: #fff;
                font-size: 14px;
            }
        }
    }
}

.footerImg{
    margin-bottom: 30px;
    width: 100%;
    max-width: 400px;
    img{
        width: 100%;
    }
}

.footerInner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.col{
    padding-right: 50px;
}

footer::before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to left, transparent, #fff, transparent);
}

footer::after{
    content:'';
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to left, transparent, #fff, transparent);
}

@media only screen and (min-width: 320px) and (max-width: 519px)  {

}

@media only screen and (max-width: 520px) {
    footer{
        padding-bottom: 60px;
    }
    .footerImg{
        width: 70%;
    }

}

@media only screen and (min-width: 960px) {

}
