.OurProgrammers{
  padding: 120px 0 60px;
  width: 100%;
  overflow-x: hidden;

  .row{
      max-width: 1500px;
  }

  .content{
      padding-left: 10%;
      padding-bottom: 60px;
  }

  .title{
      margin-bottom: 30px;
  }
  .container{
      max-width: 1500px;
      color: #fff;
  }

  .valign {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
  }

  .sub-title {
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;
      span {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #54afe6;
          position: absolute;
          left: 6px;
          bottom: 5px;
          &:nth-child(2){
              bottom: 10px;
          }
          &:nth-child(3){
              left: 0;
          }
      }
  }

  .full-width{
      width: 100% !important;
  }

  .mb-30 {
      margin-bottom: 30px !important;
  }

  .sizxl {
      width: 90%;
      max-height: 250px;
  }

 .sizmd {
      width: 65%;
      height: auto;
  }

 .sizsm {
      width: 50%;
      height: auto;
  }

  .margin-left{
      margin-left: auto;
  }

  //social icons
  
  ul{
      padding-left: 0;
      li{
          list-style: none;
          display: inline-block;
          margin: 0 20px;
          a{
              color: #fff;
              text-decoration: uppercase;
              text-decoration: none;
          }
      } 
  }

  
/* 共通のアイコンエフェクト */
.socialEffect__link {
  margin: .3em;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 1.6rem;
  background: rgba(255, 255, 255, 0.1);
  transition: transform ease-out 0.2s;
  
}

.socialEffect__box li {
  display: inline-block;
  margin: 0;

}

/* インスタ用アイコン設定 */
.icon-instagram::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0.9);
}
.fab.fa-instagram {
  display: inline-block;
}
.icon-instagram:hover {
  background: radial-gradient(
      circle farthest-corner at 32% 106%,
      rgb(255, 225, 125) 0%,
      rgb(255, 205, 105) 10%,
      rgb(250, 145, 55) 28%,
      rgb(235, 65, 65) 42%,
      transparent 82%
    ),
    linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
  transform: scale(0.9);
}

/* フェイスブック用アイコン設定 */
.icon-facebook::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0.9);
}
.fab.fa-facebook-f {
  display: inline-block;
  color: #fff;
}
.icon-facebook:hover {
  background: #1d4fbb;
  transform: scale(0.9);
}

/* ツイッター用アイコン設定 */
.icon-twitter::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0.9);
}
.fab.fa-twitter {
  display: inline-block;
  color: #fff;
}
.icon-twitter:hover {
  background: #00b0ed;
  transform: scale(0.9);
}

//github
.icon-github:hover{
    background: #9031AF;
    transform: scale(0.9)
}

//gmail
.icon-gmail:hover{
    background: #CE493B;
    transform: scale(0.9)
}


}

.mobile-img-box{
display: none;
}


@media screen and (max-width:1000px) {
  .OurProgrammers{
      .sizxl {
          width: 60%;
          max-height: 250px;
          margin-left: 30%;
          //margin-right: 20px;
               
      }
  
  }
}


@media screen and (max-width:576px){
.OurProgrammers{
  padding: 60px 0;
  //width: 100%;
  .title{
    font-size: 2rem;

  }
  .img-box{
    display: none;
  }

  .content{
    padding: 0;
    overflow-x: hidden
  }
}

.mobile-img-box{
  display: block;
  .row{
    margin:  0 auto;
  }
  .col-3{
    padding: 0 5px;
    width: 25%;
  }
}
}

