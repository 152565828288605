.CustomerBenefit {


    width: 100%;
    background: linear-gradient(180deg, #11141B 0%, #0C0F16 50%, #0C0F16 50%, #000 100%);
    color: #fff;
    //max-width: 1500px;
    margin: 0 auto;

    .valign {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sub-title {
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;
        span {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #54afe6;
            position: absolute;
            left: 6px;
            bottom: 5px;
            &:nth-child(2){
                bottom: 10px;
            }
            &:nth-child(3){
                left: 0;
            }
        }
    }

    .row{
        max-width: 1500px;
        margin: 0 auto;
        //justify-content: center; /* 主軸方向（横方向）を中央揃えにする */
        //align-items: center;
    }

    .imgs{
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
        border-radius: 10px;
        overflow: hidden;
    }

    .img1{
        height: 240px;

    }

    .img2{
        height: 240px;
    }

    .img3{
        height: 240px;
        width: 85%;
        //padding-top: 20px;
        margin-top: 20px;

    }

    .img-mons{
        padding-left: 40px;
        padding-top: 40px;
    }

    .ftbox{
        max-width: 1000px;
        margin-bottom: 100px;
        ul{
            margin: 0;
            padding: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            li{
                width: 100%;
                padding: 35px 25px;
                text-align: center;
                background: #11141b;
                border-radius: 5px;
                position: relative;
                margin-right: 10px;
                min-height: 190px;
                font-size: 2.5rem;
                h6{
                    padding-top: 5px;
                }
            }
        }
    }




}

@media screen and (max-width:1000px) {
    .img-mons{
        padding-left: 0px;
    }

    .content{
        padding-top: 80px;
        margin: 0 auto;
    }

}


@media screen and (max-width:770px) {
    .img1{
        display: none;
    }

    .img2{
        //display: none;
    }

    .img3{
        display: none;
    }
}

@media screen and (max-width:520px){
    .img2{
        width: 100%;
        margin: 0 auto;
    }

    .CustomerBenefit{
        .img-mons{
            padding: 0;
        }

        .ftbox{
            margin: 0 auto;
            ul{
                display: block;
                li{
                    padding: 10px;
                    margin: 0;
                    h6{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
}

