nav{
    width: 100%;
    display: flex;
    align-items: center;
    //align-items: right;
    justify-content: space-between;
    background: transparent;
    padding: 10px 70px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    z-index: 9999;
    position: fixed;

    .icon {
    color: white;
    padding-right: 15px;
    }

}

#navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    li{
        list-style: none;
        padding: 0 20px;
        position: relative;
        a{
            img {
                //padding-left: -50px;
            }
            text-decoration: none;
            font-size: 1.3rem;
            font-weight: 6000;
            color: #fff;
            transition: 0.3s ease-in-out;
            &:hover{
                color: #acddfa;
                &::after{
                    content: "";
                    width: 30%;
                    height: 2px;
                    background: #acddfa;
                    position: absolute;
                    bottom: -4px;
                    left: 20px;
                }
            }
            &.active{
                color: #54afe6;
            }
            &.active::after{
                content: "";
                width: 30%;
                height: 2px;
                background: #54afe6;
                position: absolute;
                bottom: -4px;
                left: 20px;
            }
        }
    }
}

#mobile{
    display: none;
    i{
        color: #fff ;
        position: relative;
    }
}

@media screen  and (max-width:1200px){ 
    #navbar{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 50px;
        right: -300px;
        width: 300px;
        height: 100vh;
        //background: #2a3239;
        background: transparent;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        transition: 0.3s ease-in-out;
        line-height: 3rem;

        img {
            padding-left: -30px;
        }

    }

    #navbar.active{
        right: 0;
    }

    #mobile{
        display: block;
        i{
            font-size: 24px;
            cursor: pointer;     
        }
    }

}


@media screen  and (max-width:520px){ 
    nav{
        padding: 0;
       
        img{
            width: 70%;
            padding: 5px 10px;
        }
    }

    #bar{
        position: sticky;
        top:0px;
        right:30px;
    }

    #navbar{
        background: #111111;
        
    }
}