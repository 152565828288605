.service{
    padding: 140px 0 ;
    background: linear-gradient(180deg, #000 0%, #0C0F16 50%, #0C0F16 50%, #11141B 100%);
    .serviceContainer{
        text-align: center ;
        max-width: 1500px;
        margin: 0 auto;
       
        .subTitle{
            color: #54afe6;
            letter-spacing: .9rem;
            font-size: 1rem;
            margin-bottom: 20px;
        }
        .title{
            font-size: 3rem;
            letter-spacing: .5rem;
            margin-bottom: 60px;
        }

        .row{
            width: 100%;
            margin: 0 auto;
            .card{
                text-align: center;
                min-height: 300px;
                padding: 80px 30px;
                h3{
                    padding: 10px 0;
                    font-size: 1.4rem;
                    color: #fff;
                }
            
                p{
                   line-height: 1.6rem;
                   color: #c8c8c8;
                }
            }
            
            .card1{
                background: url(../imgs/background/backgroundService.webp);
                background-size: cover;
                position: relative;
                border: 1px solid #fff;
                h3{
                    text-align: left;
                }
                button{
                    background: transparent;
                    color: #fff;
                    border: 1px solid ;
                    padding: 14px 14px;
                    margin-top: 40px;
                    z-index: 2;
                    &:hover{
                        transition: .3s;
                        background: #fff;
                        color: #54afe6;
                    }

                }
            }

            .card2{
                background: #11141b;
                //background-color: #181B21;
            }

            .card3{
                background-color: #181B21;
                //background-color: #1A1E25;
            }

            .card4{
                background-color: #1A1E25;
                //background-color: #20242C;
            }

            .card5{
                background-color: #1e242e;
                //background-color: #20242C;
            }
      
            .icon{
                font-size: 3rem;
            }

            .service-overlay {
                background: #02050b;
                //background: pink;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.5; // オーバーレイの透明度を調整する場合は適宜変更してください
                pointer-events: none; // マウスイベントを無効化する
                z-index: 1;
            }

            .feature{
                position: absolute;
                z-index: 2 ;
                padding: 20px 20px ;
            }
            
        }
    }
}

@media screen and (max-width:520px) {
    .service{
        padding-top: 60px;
        padding-bottom: 0;
    }

    .service .serviceContainer .row .card{
        padding: 30px 30px;
    }
    
}