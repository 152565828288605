.Chatbot {
    /*z-index: 2;*/
    /*position:fixed;*/
    z-index: 3;
    position: fixed;

    /*display: none;*/
    .closebtn {
        /*position: absolute;*/
        margin-top: -30px;
        padding-top: 10px;
        font-size: 20px;
        float: right;
        border: none;
        background: none;
    }

    .botimg {
        width: 120px;
        position: fixed;
        align-content: end;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 20px;
        right: 150px;
        bottom: 100px;
    }
    img {max-width: 100%;}
    .inbox_msg {
        border: 1px solid #c4c4c4;
        clear: both;
        overflow: hidden;
    }
    .top_spac{ margin: 20px 0 0;}


    .recent_heading {float: left; width:40%;}

    .headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

    .recent_heading h4 {
        color: #05728f;
        font-size: 21px;
        margin: auto;
    }

    .chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
    .chat_ib h5 span{ font-size:13px; float:right;}
    .chat_ib p{ font-size:14px; color:#989898; margin:auto}
    .chat_img {
        float: left;
        width: 11%;
    }
    .chat_ib {
        float: left;
        padding: 0 0 0 15px;
        width: 88%;
    }

    .chat_people{ overflow:hidden; clear:both;}
    .chat_list {
        border-bottom: 1px solid #c4c4c4;
        margin: 0;
        padding: 18px 16px 10px;
    }
    .inbox_chat { height: 550px; overflow-y: scroll;}

    .active_chat{ background:#ebebeb;}

    .incoming_msg_img {
        display: inline-block;
        width: 6%;
    }
    .hide_msg_img{ display:none;}

    .received_msg {
        display: inline-block;
        padding: 0 0 0 10px;
        vertical-align: top;
        width: 92%;
    }
    .received_withd_msg p {
        background: #e4e8fb none repeat scroll 0 0;
        border-radius: 3px;
        color: #646464;
        font-size: 14px;
        margin: 0;
        padding: 5px 10px 5px 12px;
        width: 100%;
    }
    .time_date {
        color: #747474;
        display: block;
        font-size: 10px;
        margin: 3px 0 0;
    }
    .received_withd_msg { width: 70%;}
    .mesgs {
        float: left;
        padding: 30px;
    }

    .sent_msg p {
        background: #3F51B5 none repeat scroll 0 0;
        border-radius: 3px;
        font-size: 14px;
        margin: 0; color:#fff;
        padding: 5px 10px 5px 12px;
        width:100%;
    }
    .outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
    .sent_msg {
        float: right;
        width: 70%;
        text-align: right;
    }
    .input_msg_write input {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #4c4c4c;
        font-size: 15px;
        min-height: 48px;
        width: 100%;
    }

    .type_msg {border-top: 1px solid #c4c4c4;position: relative;}
    .msg_send_btn {
        background: #05728f none repeat scroll 0 0;
        border: medium none;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        font-size: 17px;
        height: 33px;
        position: absolute;
        right: 0;
        top: 11px;
        width: 33px;
    }
    .messaging {
        /*display: none;*/
        background: #fff;
        max-width: 500px;
        /*z-index: 1;*/
        position:fixed;
        right: 150px;
        bottom: 100px;
    }
    .msg_history {
        max-height: 516px;
        overflow-y: auto;
    }

    .credit {
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .prompt-area {
        color: #fff;
        font-weight: 300;
        letter-spacing: 2px;
        /*border-bottom: dotted 1px;*/
        z-index: 2;
        /*border: none;*/

        input {
            width: 455px;
            border: 1px solid #3F3F3F;
            padding: 0 5px;
        }
        button {
            /*background: #05728f;*/
            width: 43px;
            background: transparent;
            border: 1px solid #3F3F3F;
            border-left: none !important;
            /*border: none;*/
        }
    }

}

@media screen and (max-width: 520px) {
    .Chatbot{
        .resize_img {
            top: 500px !important;
        }
        .messaging {
            right: 0;
        }
        .prompt-area {
            input {
                width: 335px;
            }
            button {
                width: 43px;
            }
        }
    }

}
