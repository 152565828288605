.scroll-to-bot {
    position: fixed;
    bottom: 50px;
    right: 50%;
    align-items: center;
    //background-color: #333;
    color: #fff;
    padding: 10px;
    //border-radius: 60%;
    display: none;
    cursor: pointer;
    &.visible {
      //display: block;
      display: flex;
      align-items: center; /* ここで中央揃えに設定 */
    }
    z-index: 999999;
    //width: 80px;
    //height: 80px;
    font-size: 3rem;


  }

@media screen and (max-width: 500px) {
  .scroll-to-bot {
    right: 40%;
  }
}
@media screen and (max-width: 1000px) {
  .scroll-to-bot {
    right: 40%;
  }
}