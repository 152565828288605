.LngBtn{
    list-style: none;
    //padding: 0 10px;
    position: relative;
    list-style: none;
    //padding: 0 20px;
    position: relative;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 6000;
    button{
    color: #fff;
    background: none;
    border: none;
    
    transition: 0.3s ease-in-out;
    &:hover{
        color: #54afe6;
        &::after{
            content: "";
            width: 30%;
            height: 2px;
            background: #54afe6;
            position: absolute;
            bottom: -4px;
            left: 20px;
        }
    }
    &.active{
        color: #54afe6;
    }
    &.active::after{
        content: "";
        width: 30%;
        height: 2px;
        background: #54afe6;
        position: absolute;
        bottom: -4px;
        left: 20px;
    }
}
}