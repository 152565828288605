.aboutUs{
    color: #fff;
    background: url("../imgs/background/background.webp") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    .aboutUsContainer{
        max-width: 1500px;
        margin: 0 auto;
        .about-text{
            padding-top: 100px;
            padding-left: 100px;         
            h2{ padding: 30px 0; }
            h3{ padding: 30px 0; }
            p{ font-size: 1.2rem; line-height: 2.5rem; }
        }
        .ftbox{
            max-width: 1000px;
            margin-bottom: 100px;
            .sub-title {
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                span {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #54afe6;
                    position: absolute;
                    left: 6px;
                    bottom: 5px;
                    &:nth-child(2){
                        bottom: 10px;
                    }
                    &:nth-child(3){
                        left: 0;
                    }
                }
            }
          
            ul{
                margin: 0;
                padding: 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                li{
                    width: 100%;
                    padding: 35px 25px;
                    text-align: center;
                    background: #11141b;
                    border-radius: 5px;
                    position: relative;
                    margin-right: 10px;
                    min-height: 190px;
                    font-size: 2.5rem;
                    h6{
                        padding-top: 5px;
                    }     
                }
            }
        }
    }
}

.icon{
    color: #54afe6;
}

@media screen and (max-width:520px){
    .aboutUs{
        .aboutUsContainer{
            .about-text{
                padding-top: 50px;
                padding-left: 0px;
                h2{ padding: 20px 0 20px 20px; }
                h3{ padding: 20px 0 20px 20px; }
                p{  padding: 20px 0 0px 20px; font-size: 1rem; line-height: 2rem; }
            }
            .ftbox{
                margin: 0 auto;
                .sub-title {
                    margin-left: 15px;
                }
                ul{
                    li{
                        padding: 10px;
                        margin: 0;
                        h6{
                            padding-top: 30px;
                        }

                    }
                }
    
            }
        }
    }


}
