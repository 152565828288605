.webCollectionContainer {

.ml-auto {
	margin-left: auto;
}
    width: 100%;
    .titles{
        padding: 120px 0 60px 0;
    }
    .subTitle{
        color: #54afe6;
        letter-spacing: .1rem;
        font-size: 1.2rem;
        margin-bottom: 20px;
        text-align: center;
    }
    .title{
        font-size: 3rem;
        letter-spacing: .5rem;
        margin-bottom: 60px;
        margin: 0 auto;
        color: #fff;
        text-align: center;

    }

    $color_1: #212121;
    $color_2: var(--main-color);
    $color_3: inherit;
    $color_4: #fff;
    $color_5: #0e0f11;
    $color_6: #a4a4a4;
    $font-family_1: "Six Caps", sans-serif;
    $border-color_1: var(--main-color);
    $border-color_2: #fff;

    @charset "UTF-8";

    .work-hed {
    	.caption {
    		h1 {
    			font-size: 90px;
    			font-weight: 600;
    			text-transform: uppercase;
    		}
    		.path {
    			span {
    				margin: 0 10px;
    			}
    		}
    	}
    }
    .work-hed2 {
    	.caption {
    		position: relative;
    		h1 {
    			span {
    				letter-spacing: normal;
    			}
    		}
    	}
    	.fit-img {
    		height: 620px;
    	}
    }
    .works-mp {
		a {
			text-decoration: none;
			color: #fff;
		}
		a:hover {
			color: #0a53be;
		}
    	padding-bottom: 100px;
    	.item {
    		padding-top: 30px;
    		border-top: 1px solid rgba(255, 255, 255, 0.2);
    		margin-bottom: 40px;
    		&:hover {
    			.cont {
    				a {
    					background: var(--main-color);
    					border-color: $border-color_1;
    				}
    			}
    		}
    		.cont {
    			h5 {
    				font-weight: 400;
    				margin-bottom: 15px;
    				color: #fff;
    			}
    			span {
    				font-size: 14px;
    				font-weight: 300;
    				text-transform: uppercase;
    				color: #fff;
    			}
    			a {
    				padding: 5px 25px;
    				border-radius: 30px;
    				border: 1px solid rgba(255, 255, 255, 0.3);
    				-webkit-transition: all .4s;
    				-o-transition: all .4s;
    				transition: all .4s;


    			}

    		}
    		.img {
    			border-radius: 10px;
    			overflow: hidden;
    			margin-top: 50px;
    			img {
    				width: 100%;
    				height: 100%;
    				-o-object-fit: cover;
    				object-fit: cover;
    				-o-object-position: center;
    				object-position: center;
    			}
    		}
    	}
    }
    .works-ca {
    	.item {
    		.img {
    			position: relative;
    			border-radius: 10px;
    			overflow: hidden;
    			.tags {
    				position: absolute;
    				left: 15px;
    				bottom: 15px;
    				opacity: 0;
    				visibility: hidden;
    				-webkit-transition: all .4s;
    				-o-transition: all .4s;
    				transition: all .4s;
    				a {
    					color: $color_1;
    					font-size: 14px;
    					padding: 7px 15px;
    					line-height: 1.5;
    					border-radius: 30px;
    					background: #fff;
    					&:hover {
    						background: var(--main-color);
    					}
    				}
    			}
    		}
    		h6 {
    			font-size: 20px;
    			font-weight: 600;
    			margin-top: 15px;
    		}
    		&:hover {
    			.img {
    				.tags {
    					opacity: 1;
    					visibility: visible;
    				}
    			}
    		}
    	}
    }
    .work-card {
    	.card-item {
    		margin-bottom: 30px;
    		.img {
    			position: relative;
    			height: 650px;
    			border-radius: 15px;
    			overflow: hidden;
    			&:after {
    				content: '';
    				position: absolute;
    				top: 0;
    				left: 0;
    				width: 100%;
    				height: 100%;
    				background-image: -webkit-gradient(linear, left top, left bottom, from(var(--theme-color)), to(transparent));
    				background-image: -webkit-linear-gradient(top, var(--theme-color), transparent);
    				background-image: -o-linear-gradient(top, var(--theme-color), transparent);
    				background-image: linear-gradient(to bottom, var(--theme-color), transparent);
    				opacity: .6;
    			}
    			.tags {
    				position: absolute;
    				top: 30px;
    				right: 30px;
    				z-index: 3;
    				a {
    					font-size: 12px;
    					line-height: 1;
    					padding: 7px 12px;
    					margin-right: 2px;
    					border: 1px solid rgba(255, 255, 255, 0.1);
    					border-radius: 30px;
    				}
    			}
    		}
    		h5 {
    			position: absolute;
    			top: 50%;
    			left: 0;
    			width: 100%;
    			text-align: center;
    			-webkit-transform: translateY(-50%);
    			-ms-transform: translateY(-50%);
    			transform: translateY(-50%);
    			font-size: 80px;
    			line-height: 1;
    			z-index: 3;
    		}
    		h5.bottom {
    			top: auto;
    			bottom: 30px;
    		}
    		h5.left {
    			text-align: left;
    			padding-left: 30px;
    		}
    		h5.right {
    			text-align: right;
    			padding-right: 30px;
    		}
    	}
    }
    .proj-details {
    	.accordion-item {
    		background: transparent;
    		border: 0;
    		border-top: 1px solid rgba(0, 0, 0, 0.2);
    		border-radius: 0;
    		.accordion-header {
    			background: transparent;
    			button {
    				background: transparent;
    				color: $color_3;
    				font-size: 20px;
    				font-weight: 500;
    				line-height: 28px;
    				padding: 15px 0;
    				-webkit-box-shadow: none;
    				box-shadow: none;
    				span {
    					font-size: 14px;
    					margin-right: 80px;
    				}
    				&:after {
    					display: none;
    				}
    			}
    		}
    		.accordion-body {
    			padding: 0 0 15px 90px;
    			p {
    				font-size: 14px;
    			}
    		}
    	}
    	.accordion-item.active {
    		border-color: $border-color_1;
    		button {
    			color: $color_2 !important;
    		}
    	}
    }
    .footer-sa {
    	.call-action {
    		h2 {
    			font-size: 100px;
    			font-weight: 600;
    			text-transform: uppercase;
    			line-height: 1;
    			position: relative;
    			.buton {
    				width: 210px;
    				height: 210px;
    				border-radius: 50%;
    				background: var(--main-color);
    				position: absolute;
    				top: -80px;
    				right: 8%;
    				font-size: 32px;
    				color: $color_1;
    				img {
    					width: 30px;
    				}
    			}
    		}
    	}
    	.logo {
    		width: 160px;
    	}
    	.contact-info {
    		.item {
    			margin-bottom: 50px;
    			span {
    				font-size: 12px;
    				text-transform: uppercase;
    				margin-bottom: 10px;
    			}
    		}
    		.social-icon {
    			a {
    				width: 48px;
    				height: 48px;
    				line-height: 48px;
    				text-align: center;
    				border: 1px solid rgba(255, 255, 255, 0.1);
    				border-radius: 50%;
    				font-size: 14px;
    				-webkit-transition: all .4s;
    				-o-transition: all .4s;
    				transition: all .4s;
    				&:hover {
    					border-color: $border-color_2;
    				}
    			}
    		}
    	}
    	.sub-footer {
    		.copy {
    			p {
    				font-size: 14px;
    				a {
    					color: $color_4;
    				}
    			}
    		}
    		.links {
    			width: 100%;
    			a {
    				font-size: 14px;
    			}
    			.active {
    				color: $color_4;
    			}
    		}
    	}
    }
    h1 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 64px;
    	letter-spacing: -3px;
    }
    h2 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 48px;
    	letter-spacing: -3px;
    }
    h3 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 40px;
    	letter-spacing: -2px;
    }
    h4 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 36px;
    	letter-spacing: -2px;
    }
    h5 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 28px;
    	letter-spacing: -1px;
    }
    h6 {
    	font-weight: 500;
    	margin: 0;
    	font-size: 22px;
    }
    .sub-bg {
    	background: var(--bg-color);
    }
    .main-color {
    	color: $color_2;
    }
    .light-bg {
    	background: #fff;
    	color: $color_5;
    	.butn.butn-bord {
    		border: 1px solid rgba(0, 0, 0, 0.3);
    		img {
    			-webkit-filter: invert(0);
    			filter: invert(0);
    		}
    		&:hover {
    			background: var(--main-color);
    			color: $color_1;
    		}
    	}
    }
    .sub-color {
    	color: $color_6;
    }
    .sub-head {
    	font-size: 20px;
    }
    .title-font {
    	font-family: $font-family_1;
    	letter-spacing: normal;
    }
    p {
    	font-size: 16px;
    	font-weight: 400;
    	color: $color_6;
    	margin: 0;
    }
    .sec-head {
    	.sub-head {
    		position: relative;
    		padding-left: 25px;
    		font-size: 18px;
    		font-weight: 300;
    		&:after {
    			content: '';
    			width: 7px;
    			height: 7px;
    			background: #fff;
    			border-radius: 50%;
    			position: absolute;
    			top: 10px;
    			left: 0;
    		}
    	}
    }
    .text-indent {
    	text-indent: 20%;
    }
    .loader-wrap {
    	svg {
    		fill: #1d1d1d;
    	}
    }
    .crv-butn {
    	span {
    		background: var(--main-color);
    		color: $color_5;
    	}
    	.text {
    		line-height: 40px;
    		font-size: 15px;
    		font-weight: 500;
    		padding: 0 20px;
    		border-radius: 30px;
    		position: relative;
    		&:after {
    			content: '';
    			width: 30px;
    			height: 30px;
    			background: var(--main-color);
    			position: absolute;
    			top: 5px;
    			right: -15px;
    			z-index: -1;
    			-webkit-transition: all .4s;
    			-o-transition: all .4s;
    			transition: all .4s;
    		}
    	}
    	.icon {
    		width: 40px;
    		height: 40px;
    		border-radius: 50%;
    		text-align: center;
    		line-height: 35px;
    		margin-left: -10px;
    		-webkit-transition: all .5s;
    		-o-transition: all .5s;
    		transition: all .5s;
    		img {
    			width: 15px;
    			-webkit-transition: all .5s;
    			-o-transition: all .5s;
    			transition: all .5s;
    		}
    	}
    	&:hover {
    		.text {
    			&:after {
    				border-radius: 50%;
    				top: calc(50% - 5px);
    				right: 0;
    				height: 10px;
    			}
    		}
    		.icon {
    			margin-left: 5px;
    			img {
    				-webkit-transform: rotate(45deg);
    				-ms-transform: rotate(45deg);
    				transform: rotate(45deg);
    			}
    		}
    	}
    }
    .butn.butn-bord {
    	border: 1px solid rgba(255, 255, 255, 0.3);
    	&:hover {
    		background: #fff;
    		color: $color_1;
    	}
    }
    .icon.invert {
    	img {
    		-webkit-filter: invert(1);
    		filter: invert(1);
    	}
    }
    .butn {
    	.icon {
    		width: 18px;
    	}
    	&:hover {
    		.icon.invert {
    			img {
    				-webkit-filter: invert(0);
    				filter: invert(0);
    			}
    		}
    	}
    }



}
@media screen and (max-width: 992px) {
    	.sec-head {
    		.sub-head {
    			margin-bottom: 15px !important;
    		}
    		.text-indent {
    			text-indent: 0;
    		}
    		.d-flex {
    			display: block !important;
    		}
    		.ml-auto {
    			margin: 15px 0 0 !important;
    		}
    	}
    	.team-hed {
    		.caption {
    			h1 {
    				font-size: 100px;
    			}
    		}
    	}
    	.pricing-hed {
    		.caption {
    			h1 {
    				font-size: 90px;
    			}
    		}
    	}
    	.contact-hed {
    		.caption {
    			h1 {
    				font-size: 90px;
    			}
    		}
    	}
    	.services-dm {
    		.item {
    			display: block !important;
    			position: relative;
    			.title {
    				h2 {
    					font-size: 60px;
    					letter-spacing: -2px;
    					margin-bottom: 10px;
    				}
    			}
    			.icon {
    				position: absolute;
    				top: 50%;
    				right: 15px;
    				margin-left: 30px;
    				-webkit-transform: translateY(-50%);
    				-ms-transform: translateY(-50%);
    				transform: translateY(-50%);
    			}
    		}
    	}
    	.services-cst {
    		.item {
    			.title {
    				h2 {
    					font-size: 30px;
    					letter-spacing: -1px;
    				}
    				.list {
    					display: none;
    				}
    			}
    			.icon {
    				width: 50px;
    				height: 50px;
    				line-height: 50px;
    				img {
    					width: 25px;
    				}
    			}
    		}
    	}
    	.intro-iner {
    		.img-box {
    			width: calc(100% - 100px);
    			margin: 0 auto 80px;
    		}
    	}
    	.team-mp {
    		.item {
    			.img {
    				width: 280px;
    			}
    			h2 {
    				font-size: 30px;
    				line-height: 1.2;
    				letter-spacing: -1px;
    			}
    		}
    	}
    	.work-hed2 {
    		.fit-img {
    			height: 400px;
    		}
    	}
    	.footer-sa {
    		.call-action {
    			h2 {
    				font-size: 70px;
    				.buton {
    					display: none !important;
    				}
    			}
    		}
    	}
    }

@media screen and (max-width: 768px) {
    	h1 {
    		letter-spacing: -1px;
    	}
    	h2 {
    		letter-spacing: -1px;
    	}
    	h3 {
    		font-size: 32px;
    		letter-spacing: normal;
    	}
    	h4 {
    		font-size: 28px;
    		letter-spacing: normal;
    	}
    	.about-hed {
    		h1 {
    			font-size: 30px;
    			letter-spacing: normal;
    		}
    	}
    	.serv-hed {
    		h1 {
    			font-size: 30px;
    			letter-spacing: normal;
    		}
    	}
    	.team-hed {
    		.caption {
    			h1 {
    				font-size: 60px;
    			}
    		}
    		.fit-img {
    			margin-top: -30px;
    		}
    	}
    	.pricing-hed {
    		.caption {
    			h1 {
    				font-size: 40px;
    			}
    		}
    	}
    	.contact-hed {
    		.caption {
    			h1 {
    				font-size: 40px;
    			}
    		}
    	}
    	.intro-iner {
    		.img-box {
    			width: 100%;
    			.states {
    				left: 30px;
    				bottom: 150px;
    			}
    		}
    	}

    	.serv-hed2 {
    		.caption {
    			h1 {
    				font-size: 40px !important;
    			}
    			.list {
    				ul {
    					padding: 0;
    				}
    			}
    		}
    	}
    	.work-hed2 {
    		h1 {
    			font-size: 40px !important;
    		}
    		.list {
    			ul {
    				padding: 0;
    			}
    		}
    	}
    	.work-hed {
    		.caption {
    			h1 {
    				font-size: 45px;
    			}
    		}
    	}
    	.work-card {
    		.card-item {
    			.img {
    				height: 500px;
    			}
    			h5 {
    				font-size: 35px;
    			}
    		}
    	}
    	.blog-hed {
    		.caption {
    			h1 {
    				font-size: 35px;
    				text-indent: 0;
    			}
    		}
    	}
    	.post-header {
    		h1 {
    			font-size: 30px !important;
    		}
    		.info {
    			display: block !important;
    		}
    	}
    	.main-post {
    		.post-qoute {
    			padding: 30px !important;
    			h6 {
    				&:before {
    					position: relative;
    					left: 0;
    				}
    			}
    		}
    		.info-area {
    			display: block !important;
    			.tags {
    				margin-bottom: 15px;
    			}
    		}
    		.author-area {
    			padding: 30px 15px;
    			.flex {
    				display: block !important;
    			}
    			.author-img {
    				margin-bottom: 15px;
    			}
    		}
    	}
    	.footer-sa {
    		.call-action {
    			h2 {
    				font-size: 35px;
    				line-height: 1.4;
    			}
    		}
    	}
    }

@media screen and (max-width:520px) {
   .title{
        padding: 50px 0;
    }

    .box{
        margin: 0 auto;
    }
}

