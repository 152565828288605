.Contact{
    color: #fff;
    max-width: 1400px;
    margin: 0 auto;
    
    width: 100%;
    padding: 50px 0;
    z-index: 99;
    min-width: 380px;
}

.titles{
    padding: 120px 0 60px 0;
}
.subTitle{
    color: #54afe6;
    letter-spacing: .1rem;
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
}
.title{
    font-size: 3rem;
    letter-spacing: .5rem;
    margin-bottom: 60px;
    margin: 0 auto;
    color: #fff;
    text-align: center;

}


.contact-info{
    display: flex;
    justify-content: center;
    margin: 50px 0;
    
    .textField{
        width: 100%;
        height: 50px;
        border: 1px solid #fff;
        box-sizing: border-box;
        background-color: transparent;
        padding: 15px 15px;
        margin-bottom: 15px;
        font-family: 'PT Sans Narrow', sans-serif;
        font-size: 16px;
        color: #FFF;
    }

    .messageField{
        height: 300px;
    }

    .submitButton{
        background-color: #fff;
        color: #000;
        height: 50px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        text-transform: uppercase;
        &:hover{
            background-color: #000;
            color: #54afe6;
            transition: .6s;
        }
    }
}



@media screen and (max-width:520px) {
    .Contact{
        padding: 0;
        .titles{
            padding: 60px 0 0;
        }
        .title{
            font-size: 2.5rem;
            padding: 30px 0;
        }
    }

}