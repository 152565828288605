.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 7%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    display: none;
    cursor: pointer;
    &.visible {
      //display: block;
      display: flex;
      align-items: center; /* ここで中央揃えに設定 */
    }
    z-index: 999999;
    //width: 80px;
    //height: 80px;
    font-size: 2rem;

  }
  